import React, { useContext } from "react";
import { Link } from "react-router-dom";
import backarrow_dark from "../images/backarrow_dark.png";
import backarrow_light from "../images/backarrow_light.png";
import { ThemeContext } from "../App";

export const Tunes = () => {
  const theme = useContext(ThemeContext).theme;

  let arrow;
  if (theme === "light") {
    arrow = backarrow_light;
    console.log(theme);
  } else {
    arrow = backarrow_dark;
    console.log(theme);
  }

  return (
    <>
      <div className="left-align-MUI-box">
        <Link to="/">
          <img className="backarrow" src={arrow} alt="go back" />
        </Link>
        <br />
        <br />

        <h2>Tunes</h2>

        <p>
          Here's my{" "}
          <a
            href="https://open.spotify.com/user/yaexqey9b89kxurt125k8hlvp"
            target="_blank"
            rel="noreferrer"
          >
            Spotify
          </a>
        </p>

        <iframe
          title="Songs that make me feel like me"
          Style={"border-radius:12px"}
          src="https://open.spotify.com/embed/playlist/4CKGxt9eRfo4wtGSdKqleE"
          width="100%"
          height="380"
          allowfullscreen=""
          allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
          loading="lazy"
        ></iframe>
      </div>
    </>
  );
};
