import React, { useContext } from "react";
import { Link } from "react-router-dom";
import backarrow_dark from "../images/backarrow_dark.png";
import backarrow_light from "../images/backarrow_light.png";
import { ThemeContext } from "../App";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";

const projects = [
  { name: "Exchanger", url: "https://exchanger.vercel.app" },
  { name: "Short Kings", url: "https://shortkings.vercel.app/" },
  { name: "TeamDiff", url: "https://www.teamdiff.xyz/" },
  { name: "Practicum", url: "https://www.practicum.us/" },
  {
    name: "Glabsauce",
    url: "https://zachglabman.notion.site/Glabsauce-3b6dcead47544e8192ac4e4be776e5d9?pvs=4",
  },
];

export const Projects = () => {
  const { theme } = useContext(ThemeContext);
  const arrow = theme === "light" ? backarrow_light : backarrow_dark;

  return (
    <div className="left-align-MUI-box">
      <Link to="/">
        <img className="backarrow" src={arrow} alt="go back" />
      </Link>
      <br></br>
      <br></br>
      <h2>Projects</h2>
      <List sx={{ maxWidth: 360 }}>
        {projects.map((project) => (
          <ListItem key={project.name} disablePadding>
            <ListItemButton component="a" href={project.url} target="_blank">
              <ListItemText
                disableTypography
                primaryTypographyProps={{ fontSize: "1em" }}
                primary={project.name}
              />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </div>
  );
};
