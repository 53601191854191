import React, { createContext, useState } from "react";
import ReactSwitch from "react-switch";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import Box from "@mui/material/Box";
import { Home } from "./components/Home";
import { About } from "./components/About";
import { Work } from "./components/Work";
import { Recently } from "./components/Recently";
import { Tunes } from "./components/Tunes";
import { Projects } from "./components/Projects";
// import glabChili from "./images/glabchili.png";
import glabChili_dark from "./images/glabchili_darkmode.png";
import glabChili_light from "./images/glabchili_lightmode.png";
import "./App.css";

export const ThemeContext = createContext(null);

function App() {
  const [theme, setTheme] = useState("light");

  const toggleTheme = () => {
    setTheme((curr) => (curr === "light" ? "dark" : "light"));
  };

  const chili = theme === "light" ? glabChili_light : glabChili_dark;

  return (
    <ThemeContext.Provider value={{ theme, toggleTheme }}>
      <div id={theme}>
        <div className="mode-switch">
          <label>{theme === "light" ? "Light Mode" : "Dark Mode"}</label>
          <ReactSwitch onChange={toggleTheme} checked={theme === "dark"} />
        </div>
        <div className="main">
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            sx={{ flexDirection: "column", width: "100%", bgcolor: theme }}
          >
            <Router>
              <Link to="/">
                <img id="chili" src={chili} alt="zach glabman logo" />
              </Link>
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/about" element={<About />} />
                <Route path="/work" element={<Work />} />
                <Route path="/projects" element={<Projects />} />
                <Route path="/tunes" element={<Tunes />} />
                <Route path="/recently" element={<Recently />} />
              </Routes>
            </Router>
          </Box>
        </div>
      </div>
    </ThemeContext.Provider>
  );
}

export default App;
