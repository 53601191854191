import React, { useContext } from "react";
import { Link } from "react-router-dom";
import backarrow_dark from "../images/backarrow_dark.png";
import backarrow_light from "../images/backarrow_light.png";
import { ThemeContext } from "../App";

export const About = () => {
  const { theme } = useContext(ThemeContext);
  const arrow = theme === "light" ? backarrow_light : backarrow_dark;

  return (
    <div className="left-align-MUI-box">
      <div className="page-title">
        <Link to="/">
          <img className="backarrow" src={arrow} alt="go back" />
        </Link>
        <br></br>
        <br></br>
        <h2>About</h2>
      </div>
      <br></br>
      <p>
        Studying Linguistics @{" "}
        <a href="https://ox.ac.uk/" target="_blank" rel="noreferrer">
          Oxford
        </a>
      </p>
      <p>
        Investing (pre-seed) in outliers @{" "}
        <a href="https://breakers.vc/" target="_blank" rel="noreferrer">
          Breakers
        </a>
      </p>
      <p>Love scuba diving, golf, travel, cooking, hot sauce</p>
    </div>
  );
};
