import React, { useState } from "react";
import { Link } from "react-router-dom";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";

const titlesArray = ["welcome!", "how's your day?", "say hi on twitter :)"];

export const Home = () => {
  const [text, setText] = useState("Hi, I'm Zach 👋");

  const textChange = () => {
    const randomIndex = Math.floor(Math.random() * titlesArray.length);
    setText(titlesArray[randomIndex]);
  };

  return (
    <div className="left-align-MUI-box">
      <h2 className="clickable" onClick={textChange}>
        {text}
      </h2>
      <List sx={{ maxWidth: 360 }}>
        {["About", "Work", "Projects", "Tunes", "Recently"].map((item) => (
          <ListItem key={item} disablePadding>
            <ListItemButton component={Link} to={`/${item.toLowerCase()}`}>
              <ListItemText
                disableTypography
                primaryTypographyProps={{ fontSize: "1em" }}
                primary={item}
              />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </div>
  );
};
