import * as React from "react";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";

import loquita from "../images/recently/loquita.jpeg";
import skydiving from "../images/recently/skydiving.JPG";
import oregoncoast from "../images/recently/oregoncoast.JPG";
import crosslawn from "../images/recently/crosslawn.jpeg";
import charcuterie from "../images/recently/sfcharcuterie.jpeg";
import seattle from "../images/recently/seattle.JPG";
import mailbox3 from "../images/recently/mailbox3.JPG";
import mailbox2 from "../images/recently/mailbox2.JPG";
import mailbox from "../images/recently/mailbox.jpeg";
import wug from "../images/recently/wug.JPG";
import ollie from "../images/recently/ollie.jpeg";
import myfriend from "../images/recently/myfriendmyfriend.jpeg";
import ollie2 from "../images/recently/ollie2.jpeg";
import roma from "../images/recently/roma.jpeg";
import navigli from "../images/recently/navigli.jpeg";
import skitrip from "../images/recently/skitrip.jpeg";
import iceskating from "../images/recently/iceskating.jpeg";
import miami from "../images/recently/miami.jpeg";
import kaapo from "../images/recently/kaapo.jpeg";
import gentsinla from "../images/recently/gentsinla.JPG";
import kth from "../images/recently/kth.jpeg";
import sthlm from "../images/recently/sthlm.jpeg";
import estonia from "../images/recently/estonia.jpeg";
import hotsauce from "../images/recently/hotsauce.JPG";
import deanvillage from "../images/recently/deanvillage.jpeg";
import subaqua from "../images/recently/subaqua.jpeg";
import lochness from "../images/recently/lochness.jpeg";
import lolla from "../images/recently/lolla.jpg";
import tor from "../images/recently/tor.jpeg";
import edigardens from "../images/recently/edigardens.jpeg";
import standrews from "../images/recently/standrews.jpeg";
import bfast from "../images/recently/scottishbfast.jpeg";
import books from "../images/recently/books.jpeg";
import oslo from "../images/recently/oslo.jpeg";
import chilies from "../images/recently/chilies.jpeg";
import firbush from "../images/recently/firbush.jpeg";
import luzern from "../images/recently/luzern.jpeg";
import pilatus from "../images/recently/pilatus.jpeg";
import sargans from "../images/recently/sargans.jpeg";
import wien from "../images/recently/wien.jpeg";
import bratbrunch from "../images/recently/bratbrunch.jpeg";
import vaduz from "../images/recently/vaduz.jpeg";
import schloss from "../images/recently/schloss.jpeg";
import bratislava from "../images/recently/bratislava.jpeg";
import wineball from "../images/recently/wineball.JPG";
import zurich from "../images/recently/zurich.jpeg";
import emwater from "../images/recently/emwater.JPG";
import brunchvibe from "../images/recently/brunchvibe.jpeg";
import whiskytasting from "../images/recently/whiskytasting.jpeg";
import arthurs from "../images/recently/arthurs.JPG";
import roadtrip from "../images/recently/roadtrip.jpeg";

export const Recently = () => {
  const itemData = [
    {
      img: brunchvibe,
      title: "whatll it be",
      author: "@zachglabman",
    },
    {
      img: whiskytasting,
      title: "Whisky Tasting",
      author: "@zachglabman",
    },
    {
      img: arthurs,
      title: "Arthurs Seat",
      author: "@zachglabman",
    },
    {
      img: roadtrip,
      title: "Highlands Roadtrip",
      author: "@zachglabman",
    },
    {
      img: zurich,
      title: "Zurich, CH",
      author: "@zachglabman",
    },
    {
      img: emwater,
      title: "St A",
      author: "@zachglabman",
    },
    {
      img: bratislava,
      title: "Bratislava",
      author: "@zachglabman",
    },
    {
      img: wineball,
      title: "Christmas Ball",
      author: "@zachglabman",
    },
    {
      img: schloss,
      title: "Schloss",
      author: "@zachglabman",
    },
    {
      img: wien,
      title: "Wien, AT",
      author: "@zachglabman",
    },
    {
      img: bratbrunch,
      title: "Bratislav Brunch",
      author: "@zachglabman",
    },
    {
      img: vaduz,
      title: "Vaduz, LIE",
      author: "@zachglabman",
    },
    {
      img: luzern,
      title: "Luzern, CH",
      author: "@zachglabman",
    },
    {
      img: pilatus,
      title: "Mt Pilatus",
      author: "@zachglabman",
    },
    {
      img: sargans,
      title: "Sargans, CH",
      author: "@zachglabman",
    },
    {
      img: firbush,
      title: "Firbush",
      author: "@zachglabman",
    },
    {
      img: chilies,
      title: "Chilies",
      author: "@zachglabman",
    },
    {
      img: oslo,
      title: "Oslo",
      author: "@zachglabman",
    },
    {
      img: books,
      title: "Bookstore",
      author: "@zachglabman",
    },
    {
      img: bfast,
      title: "Scottish Breakfast",
      author: "@zachglabman",
    },
    {
      img: standrews,
      title: "St. Andrews",
      author: "@zachglabman",
    },
    {
      img: edigardens,
      title: "Castle",
      author: "@zachglabman",
    },
    {
      img: tor,
      title: "Tor",
      author: "@zachglabman",
    },
    {
      img: lolla,
      title: "Lolla Berlin",
      author: "@zachglabman",
    },
    {
      img: lochness,
      title: "Loch Ness",
      author: "@zachglabman",
    },
    {
      img: subaqua,
      title: "Cramond Island",
      author: "@zachglabman",
    },
    {
      img: deanvillage,
      title: "Dean Village (Edi)",
      author: "@zachglabman",
    },
    {
      img: loquita,
      title: "Loquita SB",
      author: "@zachglabman",
    },
    {
      img: crosslawn,
      title: "Cross in Newport",
      author: "@zachglabman",
    },
    {
      img: oregoncoast,
      title: "Oregon Coast",
      author: "@zachglabman",
    },
    {
      img: skydiving,
      title: "Skydiving in SEA",
      author: "@zachglabman",
    },
    {
      img: charcuterie,
      title: "Charcuterie",
      author: "@zachglabman",
    },
    {
      img: mailbox2,
      title: "Mailbox Peak 2",
      author: "@zachglabman",
    },
    {
      img: mailbox3,
      title: "Malibox Peak 3",
      author: "@zachglabman",
    },
    {
      img: seattle,
      title: "Seattle Coast",
      author: "@zachglabman",
    },
    {
      img: mailbox,
      title: "Mailbox Peak",
      author: "@zachglabman",
    },
    {
      img: wug,
      title: "Wug Club Exec",
      author: "@zachglabman",
    },
    {
      img: ollie,
      title: "Oli",
      author: "@zachglabman",
    },
    {
      img: myfriend,
      title: "Dude who scammed Oli",
      author: "@zachglabman",
    },
    {
      img: ollie2,
      title: "Milanese",
      author: "@zachglabman",
    },
    {
      img: roma,
      title: "Colosseum",
      author: "@zachglabman",
    },
    {
      img: navigli,
      title: "Navigli Film",
      author: "@zachglabman",
    },
    {
      img: skitrip,
      title: "Will and I in Breck",
      author: "@zachglabman",
    },
    {
      img: iceskating,
      title: "Good times in STL",
      author: "@zachglabman",
    },
    {
      img: miami,
      title: "WashU tech trip to MIA",
      author: "@zachglabman",
    },
    {
      img: kaapo,
      title: "Coffee with a barista champion",
      author: "@zachglabman",
    },
    {
      img: gentsinla,
      title: "Jake, Will and I in LA",
      author: "@zachglabman",
    },
    {
      img: kth,
      title: "Saga giving me a tour of KTH",
      author: "@zachglabman",
    },
    {
      img: sthlm,
      title: "Gamla Stan",
      author: "@zachglabman",
    },
    {
      img: estonia,
      title: "Tallinn vibes",
      author: "@zachglabman",
    },
    {
      img: hotsauce,
      title: "Sauce pic",
      author: "@zachglabman",
    },
  ];
  return (
    <>
      <ImageList
        m={0}
        p={0}
        sx={{ width: "80vw", height: "70vh" }}
        cols={3}
        gap={"2vw"}
      >
        {itemData.map((item) => (
          <ImageListItem key={item.img}>
            <img
              src={`${item.img}?w=164&h=164&fit=crop&auto=format`}
              srcSet={`${item.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
              // src={item.img}
              // srcSet={`${item.img}?dpr=2 2x`}
              alt={item.title}
              loading="lazy"
            />
          </ImageListItem>
        ))}
      </ImageList>
    </>
  );
};
