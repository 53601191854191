import React, { useContext } from "react";
import { Link } from "react-router-dom";
import backarrow_dark from "../images/backarrow_dark.png";
import backarrow_light from "../images/backarrow_light.png";
import { ThemeContext } from "../App";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";

const workPlaces = [
  { name: "Breakers", url: "https://breakers.vc/" },
  { name: "Ramp", url: "https://ramp.com/" },
  { name: "Taika", url: "https://taika.co/" },
  { name: "Tesla", url: "https://tesla.com/" },
  { name: "Anthos Capital", url: "https://anthoscapital.com/" },
];

export const Work = () => {
  const { theme } = useContext(ThemeContext);
  const arrow = theme === "light" ? backarrow_light : backarrow_dark;

  return (
    <div className="left-align-MUI-box">
      <Link to="/">
        <img className="backarrow" src={arrow} alt="go back" />
      </Link>
      <br></br>
      <br></br>
      <h2>Work</h2>
      <List sx={{ maxWidth: 360 }}>
        {workPlaces.map((place) => (
          <ListItem key={place.name} disablePadding>
            <ListItemButton component="a" href={place.url} target="_blank">
              <ListItemText
                disableTypography
                primaryTypographyProps={{ fontSize: "1em" }}
                primary={place.name}
              />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </div>
  );
};
